import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import { useImage } from "@queries/use-image";

const Image = ({ filename, altText, ...props }) => {
  const { getFluidImageName } = useImage();

  return (
    <div {...props}>
      <Img fluid={getFluidImageName(filename)} alt={altText} />
    </div>
  );
};

Image.propTypes = {
  filename: PropTypes.string,
  altText: PropTypes.string,
};

export default Image;
