/* eslint-disable react/prop-types */
import {
  CenteredColumn,
  HeroBanner,
  Image,
  Paragraph,
  TwoColumnsBlock,
} from "@components/mdx";
import Block from "@components/block/block-component";
import EmptySpace from "@components/empty-space/component";
import FeedCardGrid from "@components/feed/feed-card-grid-component";
import FullBleed from "@components/full-bleed";
import Layout from "@components/layout";
import { Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Newsletter from "@components/newsletter/newsletter-component";
import React from "react";
import Seo from "@components/seo";
import Title from "@components/title/title-component";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useFeedCollection } from "@queries/use-feed";
import { useImage } from "@queries/use-image";

const MDXContainer = styled.div`
  width: 100%;
`;

const shortcodes = {
  CenteredColumn,
  EmptySpace,
  HeroBanner,
  TwoColumnsBlock,
  Link,
  Paragraph,
  Block,
  Image,
  Title,
};

export default function PageTemplate({ data: { mdx } }) {
  const { getFluidImageName } = useImage();
  const { getAllFeedFrontmatter } = useFeedCollection();
  const allFeed = getAllFeedFrontmatter();
  // filter all highlighted feed items
  // and remove current item from this list
  // to avoid repetition
  const list = allFeed
    .filter((item) => item.highlighted)
    .filter((item) => item.id !== mdx.id);

  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 170 }} mobile={{ margin: 180 }} />

      {/* dont remove this div */}
      <div>
        <MDXContainer>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </MDXContainer>
      </div>

      <EmptySpace desktop={{ margin: 80 }} mobile={{ margin: 120 }} />

      {list.length > 0 && (
        <React.Fragment>
          <Title value="Vê também" />
          <EmptySpace desktop={{ margin: 30 }} mobile={{ margin: 40 }} />
          <FeedCardGrid list={list} />
          <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 60 }} />
        </React.Fragment>
      )}

      <FullBleed>
        <Newsletter />
      </FullBleed>

      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.summary}
        image={getFluidImageName(
          mdx.frontmatter.thumbnailImage || "class-with-students"
        )}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query FeedPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        category
        summary
        thumbnailImage
        thumbnailImageAltText
        highlighted
      }
    }
  }
`;
