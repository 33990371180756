import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const ColumnsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;

  @media (max-width: 768px) {
  }
`;

const TwoColumnsBlock = ({ children, ...props }) => {
  return <ColumnsContainer {...props}>{children}</ColumnsContainer>;
};

TwoColumnsBlock.propTypes = {
  children: PropTypes.node,
};

export default TwoColumnsBlock;
