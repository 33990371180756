import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledParagraph = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Paragraph = ({ children, ...props }) => {
  return <StyledParagraph {...props}>{children}</StyledParagraph>;
};

Paragraph.propTypes = {
  children: PropTypes.node,
};

export default Paragraph;
