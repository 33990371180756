import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useImage } from "@queries/use-image";

const Container = styled(Img)`
  background-color: transparent;
  border-radius: 5px;
  height: 400px;

  picture {
    object-fit: cover;
  }

  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

const HeroBanner = ({ filename, altText, ...props }) => {
  const { getFluidImageName } = useImage();

  return (
    <Container fluid={getFluidImageName(filename)} alt={altText} {...props} />
  );
};

HeroBanner.propTypes = {
  filename: PropTypes.string,
  altText: PropTypes.string,
};

export default HeroBanner;
