import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 730px;
  margin: 0 auto;

  @media (max-width: 768px) {
    margin: unset;
    max-width: unset;
  }
`;

const CenteredColumn = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

CenteredColumn.propTypes = {
  children: PropTypes.node,
};

export default CenteredColumn;
